<template>
  <AppLayout>
    <template v-slot:appContent>
      <section class="users-list-wrapper">
        <div class=" d-sm-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Coupons/Usages</h4>
          <div>
            <router-link :to="can('coupon-create') ? {name:'appCouponCreate'} : '#'">
              <span :title="can('coupon-create') ? 'Create' : 'Create Forbidden'"
                    class="glow d-flex align-items-center">
                <i class='bx bx-plus bx-flip-vertical'></i>&nbsp;Create</span>
            </router-link>
          </div>
        </div>
        <div class="users-list-filter px-1 my-2 py-2 border rounded">
          <div>
            <div class="row align-items-center ">
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label>Show Per Page</label>
                <VueMultiselect :allowEmpty="false" v-model="selectedPagination" class="" :options="paginationOptions"
                                :close-on-select="true"
                                label="name" track-by="name" :show-labels="false"/>
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label>Coupon Code</label>
                <input v-model="getCouponUsagesParams.where_has_coupon_code"
                       class="form-control search-product-input-element"
                       type="text" placeholder="Search by Code">
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label>Discount From</label>
                <input v-model="discountFrom"
                       class="form-control search-product-input-element"
                       type="text" placeholder="Discount From">
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label>Discount To</label>
                <input v-model="discountTo"
                       class="form-control search-product-input-element"
                       type="text" placeholder="Discount To">
              </div>
            </div>
            <div class="row pt-2">
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label>Customer Search</label>
                <input v-model="getCouponUsagesParams.where_has_user_query"
                       class="form-control search-product-input-element" type="text"
                       placeholder="Search a customer by name or email">
              </div>
              <div class="col-12 col-sm-6 col-md-3  col-lg-3">
                <div class="form-group">
                  <label>Created Date From</label>
                  <div class="d-flex align-items-center date-picker-wrapper">
                    <div class="d-flex align-items-center">
                      <i class='bx bx-calendar'></i>
                    </div>
                    <div class="w-100 pl-1 ">
                      <div class="w-100 ">
                        <DatePicker v-model="startDate" :update-on-input="true"
                                    :masks="{input: ['DD MMMM YYYY']}"
                                    :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                          <template v-slot="{ inputValue, inputEvents }">
                            <input class="form-control" :value="inputValue" v-on="inputEvents"/>
                          </template>
                        </DatePicker>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-3  col-lg-3">
                <div class="form-group">
                  <label>Created Date To</label>
                  <div class="d-flex align-items-center date-picker-wrapper">
                    <div class="d-flex align-items-center">
                      <i class='bx bx-calendar'></i>
                    </div>
                    <div class="w-100 pl-1 ">
                      <div class="w-100 ">
                        <DatePicker v-model="endDate" :update-on-input="true"
                                    :masks="{input: ['DD MMMM YYYY']}"
                                    :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                          <template v-slot="{ inputValue, inputEvents }">
                            <input class="form-control" :value="inputValue" v-on="inputEvents"/>
                          </template>
                        </DatePicker>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-lg-3 pt-2">
                <button type="reset" class="btn btn-primary btn-block glow users-list-clear mb-0"
                        @click="applyCouponUsagesFilter(null)">Filter
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="users-list-table">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table id="users-list-datatable" class="table">
                  <thead>
                  <tr>
                    <th class="position-relative" :style="{width:'5%'}">SL</th>
                    <th class="position-relative" :style="{width:'25%'}">CUSTOMER</th>
                    <th class="position-relative" :style="{width:'20%'}">COUPON</th>
                    <th class="position-relative" :style="{width:'15%'}">USAGES REFERENCE</th>
                    <th class="position-relative" :style="{width:'15%'}">DISCOUNT AMOUNT</th>
                    <th class="position-relative" :style="{width:'20%'}">CREATED AT</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(couponUsage, index) in couponUsages" :key="index">
                    <td>{{ index+1 }}</td>
                    <td>
                      <span class="customer-name" v-if="couponUsage.user">
                          {{ couponUsage.user.first_name }} {{ couponUsage.user.last_name }}
                      </span><br>
                      <span><strong class="text-uppercase">Email: </strong>
                        {{ couponUsage.user.email }}
                      </span><br>
                      <span><strong class="text-uppercase">Phone No.: </strong>
                        {{ couponUsage.user.phone_number }}
                      </span>

                    </td>
                    <td>
                      <span><strong>Name: </strong>{{ couponUsage.coupon.name }}</span><br>
                      <span><strong>Code: </strong>{{ couponUsage.coupon.code }}</span><br>
                      <span><strong>Apply On: </strong>{{ couponUsage.coupon.apply_on }}</span><br>
                    </td>
                    <td>{{ couponUsage.reference }}</td>
                    <td>${{ (couponUsage.discount_amount/100).toFixed(2) }}</td>
                    <td>
                      {{ couponUsage.created_at }}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <ListPagination position="right" :pagination-data="paginateLinks"
                              @getClickedPage="applyCouponUsagesFilter"/>
            </div>
          </div>
        </div>
      </section>
    </template>
  </AppLayout>
</template>

<script>
// components
import AppLayout from '@/layouts/backEnd/AppLayout.vue'
import ListPagination from "@/components/backEnd/pagination/ListPagination";
// mixins
import Authorization from "@/components/backEnd/mixins/Authorization";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
// core packages
import {mapActions, mapGetters} from "vuex";
// 3rd party packages
import {DatePicker} from "v-calendar";

export default {
  name: "ListCouponUsages",
  components: {
    AppLayout,
    ListPagination,
    DatePicker
  },
  mixins: [ShowToastMessage, Loader, Authorization],
  data() {
    return {
      getSettingsParams: {
        type: ['default'],
        key: ['pagination'],
      },

      getCouponUsagesParams: {
        where_has_user_query: '',
        where_has_coupon_code: '',
        discount_amount: [],
        created_at: [],
        with_relation: ['coupon', 'user', 'user.customer.address'],
        order_by_id: 'desc',
        paginate: 1,
        pagination: '',
        page: '',
        coupon_id: ''
      },
      selectedPagination: {
        value: '',
        name: 'Default'
      },
      discountFrom: '',
      discountTo: '',
      startDate: '',
      endDate: '',
    }

  },
  watch: {
    selectedPagination: function (pagination) {
      this.getCouponUsagesParams.pagination = pagination.value;
    },
    discountFrom(discountFrom) {
      this.getCouponUsagesParams.discount_amount[0] = discountFrom * 100;
    },
    discountTo(discountTo) {
      this.getCouponUsagesParams.discount_amount[1] = discountTo * 100;
    },
    startDate() {
      this.getCouponUsagesParams.created_at[0] = this.startDate !== '' ? this.startDate + ' 00:00:00' : '';
    },
    endDate() {
      this.getCouponUsagesParams.created_at[1] = this.endDate !== '' ? this.endDate + ' 23:59:59' : '';
    }
  },
  computed: {
    ...mapGetters({
      paginateLinks: 'appCouponUsages/paginateLinks',
      couponUsages: 'appCouponUsages/couponUsages',
    }),

    paginationOptions() {
      return [
        {value: this.$store.getters['appSettings/settingDefaultPagination'].value, name: 'Default'},
        {value: 25, name: '25 Entries'},
        {value: 50, name: '50 Entries'},
        {value: 100, name: '100 Entries'}
      ];
    },
  },
  methods: {
    ...mapActions({
      getSettings: 'appSettings/getSettings',
      getCouponUsages: 'appCouponUsages/getCouponUsages',
      getCouponUsage: 'appCouponUsages/getCouponUsage',
    }),
    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },

    async getCouponUsagesList() {
      await this.getCouponUsages(this.getCouponUsagesParams).then((response) => {
            if (response.status !== 200) {
              this.showToastMessage(response);
            }
      });
    },

    async applyCouponUsagesFilter(pageNumber) {
      await this.loader(true);
      this.getCouponUsagesParams.page = pageNumber;
      await this.getCouponUsagesList(this.getCouponUsagesParams);
      await this.loader(false);
    },
  },
  async mounted() {
    await this.loader(true);
    let code = await this.$route.params.code;
    if (code) this.getCouponUsagesParams.coupon_id = await code;

    await this.getSettingList();
    await this.getCouponUsagesList();
    await this.loader(false);
  },

}
</script>

<style scoped>

</style>